import styled, { css } from 'styled-components';

import { Wrapper as Grid } from 'Components/Grid/Grid.styled';
import { Wrapper as Col } from 'Components/Grid/Col/Col.styled';

import type { Padding, PaddingWithValue } from 'Elements/Section/Section';

import Block from 'Components/Block';

interface WrapperProps {
    $backgroundColor?: React.CSSProperties['backgroundColor'];
    $overflow?: React.CSSProperties['overflow'];
    $padding?: Padding;
    $paddingTop?: PaddingWithValue;
    $paddingBottom?: PaddingWithValue;
    $noMarginTop?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    ${({
        theme,
        $backgroundColor,
        $overflow,
        $padding,
        $paddingTop,
        $paddingBottom,
        $noMarginTop
    }) => css`
        overflow: ${$overflow};
        background-color: ${$backgroundColor};
        margin-top: 3rem;

        && {
            &:first-of-type {
                margin-top: 0;
            }

            ${$noMarginTop &&
            css`
                margin-top: 0;
            `}

            ${$padding !== 'none' &&
            css`
                ${$paddingTop !== 'none' &&
                typeof $paddingTop === 'number' &&
                css`
                    padding-top: ${$paddingTop * 2}rem;

                    @media ${theme.breakpoints.large.media} {
                        padding-top: ${$paddingTop * 3}rem;
                    }
                `}
                ${$paddingBottom !== 'none' &&
                typeof $paddingBottom === 'number' &&
                css`
                    padding-bottom: ${$paddingBottom * 2}rem;

                    @media ${theme.breakpoints.large.media} {
                        padding-bottom: ${$paddingBottom * 3}rem;
                    }
                `}
            `}
        }

        & > ${Grid} > ${Col} > *:first-child,
        & > *:first-child {
            margin-top: 0;
        }

        h2,
        h3 :first-of-type {
            margin-top: 0;
        }
    `}
`;

export const AccordionHeading = styled.span`
    ${({ theme }) => css`
        font-size: 1.25rem;
        display: block;

        @media ${theme.breakpoints.large.media} {
            font-size: 1.5rem;
        }
    `}
`;

export const StyledBlock = styled(Block)`
    border: none;
`;

export const MobileHidden = styled.span`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
        }
    `}
`;
