import React from 'react';

import { Wrapper } from './Block.styled';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    style?: React.CSSProperties;
}

const Block: React.FC<Props> = props => {
    const { children, style, ...rest } = props;

    return (
        <Wrapper style={style} {...rest}>
            {children}
        </Wrapper>
    );
};

export default Block;
