import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2.2rem;
`;

export const Container = styled.div<{ $image: string }>`
    ${({ $image }) => css`
        position: relative;
        overflow: hidden;
        width: 100%;

        ::before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(${$image}) no-repeat fixed 50% 50%;
            background-size: cover;
            z-index: -1;
        }
    `}
`;

export const Content = styled(animated.div)<{ $border: boolean }>`
    ${({ theme, $border }) => css`
        will-change: transform;
        position: relative;
        border-radius: 1rem;
        padding: 1rem;
        background-color: ${theme.colors.secondary.tints[0].hex};
        border: ${$border ? '0.25rem solid' + [theme.colors.secondary.tints[8].hex] : 'none'};
        margin: 8rem 0rem;

        @media ${theme.breakpoints.large.media} {
            padding: 1rem;
        }
    `}
`;

export const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
`;

export const ButtonWrapper = styled.div`
    position: absolute;
    top: -2rem;
    z-index: 1;
`;
