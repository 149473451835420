import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        padding: 1.5rem 1rem;
        border: ${theme.commonStyles.brandPixel} solid ${theme.colors.secondary.tints[2].hex};
        border-radius: 0.5rem;
        background: ${theme.colors.background.hex};

        @media ${theme.breakpoints.medium.media} {
            padding: 1.5rem;
        }

        aside & {
            margin-bottom: 1.5rem;
            padding: 0.75rem;

            @media ${theme.breakpoints.medium.media} {
                padding: 0.75rem;
            }
        }
    `}
`;
