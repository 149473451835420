import styled, { css } from 'styled-components';

import { ReactComponent as QuoteIcon } from 'Public/graphics/icons/quote-mark.svg';

import {
    Wrapper as ParallaxWrapper,
    Container as ParallaxContainer
} from 'Modules/Banner/Parallax/Parallax.styled';

export const Container = styled.figure`
    display: inline-block;
    margin: 0;
`;

export const Quote = styled.p`
    ${({ theme }) => css`
        position: relative;
        margin: 0;
        z-index: 1;
        font-size: 1.5rem;

        @media ${theme.breakpoints.large.media} {
            line-height: 1.25;
            font-size: 2rem;
        }
    `}
`;

const sharedStyles = css`
    position: absolute;
    opacity: 0.15;
`;

export const LeftQuoteIcon = styled(QuoteIcon)`
    ${({ theme }) => css`
        ${sharedStyles}
        top: -1.75rem;
        left: -0.7rem;
        width: 5rem;
        height: 6rem;

        @media ${theme.breakpoints.large.media} {
            top: -1rem;
            left: -0.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            top: -1rem;
            left: -0.5rem;
            width: 8rem;
            height: 6rem;
        }
    `}
`;

export const RightQuoteIcon = styled(QuoteIcon)`
    ${({ theme }) => css`
        ${sharedStyles}
        bottom: -1.5rem;
        right: -0.5rem;
        transform: rotate(180deg);
        width: 5rem;
        height: 6rem;
        /*keep icon on mobile if author isnt present*/

        @media ${theme.breakpoints.medium.media} {
            width: 6rem;
            height: 5rem;
            bottom: -1rem;
            right: -0.25rem;
        }

        @media ${theme.breakpoints.large.media} {
            bottom: -1rem;
            right: -0.25rem;
            width: 8rem;
            height: 6rem;
        }
    `}
`;

export const Blockquote = styled.blockquote`
    ${({ theme }) => css`
        position: relative;
        margin: 2rem 1.5rem 0.5rem;

        @media ${theme.breakpoints.medium.media} {
            margin: 2rem 4rem 0.5rem;
            font-size: 1.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 2rem;
            margin: 2rem 6rem 1.5rem;
        }
    `}
`;

export const Figcaption = styled.figcaption`
    ${({ theme }) => css`
        color: white;
        margin: 0 1.5rem 1.5rem;
        font-size: 1.125rem;

        @media ${theme.breakpoints.medium.media} {
            font-size: 1.25rem;
            margin: 0 4rem 3rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 0 6rem 3rem;
        }
    `}
`;

export const Wrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        overflow: hidden;
        font-family: ${theme.fonts.secondary};
        border-radius: 0.5rem;
        background-color: var(--quote-bg-color, ${theme.colors.tertiary.hex});

        @media ${theme.breakpoints.medium.media} {
            margin: 0 2.5rem;
        }

        ${ParallaxWrapper} & {
            margin: 0;
        }

        ${ParallaxContainer} & {
            overflow: unset;
        }

        ${RightQuoteIcon} {
            color: var(--quote-color-right, ${theme.colors.secondary.hex});
        }

        ${LeftQuoteIcon} {
            color: var(--quote-color-left, ${theme.colors.secondary.hex});
        }

        ${Quote} {
            color: var(--quote-text-color, ${theme.colors.accents[3].hex});
        }

        ${Container} {
            color: var(--quote-text-color, ${theme.colors.accents[3].hex});
        }
    `}
`;
