import React from 'react';

import {
    LeftQuoteIcon,
    RightQuoteIcon,
    Quote,
    Container,
    Wrapper,
    Blockquote,
    Figcaption
} from './QuoteBlock.styled';

export interface Props {
    children: React.ReactNode | string;
    cite?: { author?: string; citation?: string; url?: string };
    quoteColorLeft?: string;
    quoteColorRight?: string;
    backgroundColor?: string;
    textColor?: string;
}

const QuoteBlock: React.FC<Props> = props => {
    const { children, cite, quoteColorLeft, quoteColorRight, backgroundColor, textColor } = props;

    return (
        <Wrapper
            style={
                {
                    '--quote-bg-color': backgroundColor,
                    '--quote-text-color': textColor,
                    '--quote-color-left': quoteColorLeft,
                    '--quote-color-right': quoteColorRight
                } as React.CSSProperties
            }
        >
            <Container>
                <Blockquote cite={cite?.url ? cite.url : undefined}>
                    <Quote>{children}</Quote>
                </Blockquote>
                {cite && (
                    <Figcaption>
                        {cite.author && <span>- {cite.author}</span>}
                        {cite.citation && (
                            <>
                                {cite.author && (
                                    <>
                                        , <br />
                                    </>
                                )}
                                <cite>{cite.citation}</cite>
                            </>
                        )}
                    </Figcaption>
                )}
            </Container>
            <LeftQuoteIcon />
            <RightQuoteIcon />
        </Wrapper>
    );
};

export default QuoteBlock;
