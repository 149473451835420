import React from 'react';
import type CSS from 'csstype';

import { BreakpointNames, SpacingWithBreakpoints } from 'Theme/types/breakpoints.type';

interface Props {
    as?: React.ReactNode;
    size?:
        | CSS.Property.FontSize<string>
        | Partial<Record<BreakpointNames, CSS.Property.FontSize<string>>>;
    weight?: CSS.Property.FontWeight;
    fontFamily?: CSS.Property.FontFamily;
    color?: CSS.Property.Color;
    textAlign?: CSS.Property.TextAlign;
    spacing?: SpacingWithBreakpoints;
    lineHeight?: CSS.Property.LineHeight;
    children?: string | React.ReactNode;
}

export interface StyledProps {
    $size?:
        | CSS.Property.FontSize<string>
        | Partial<Record<BreakpointNames, CSS.Property.FontSize<string>>>;
    $weight?: CSS.Property.FontWeight;
    $fontFamily?: CSS.Property.FontFamily;
    $color?: CSS.Property.Color;
    $textAlign?: CSS.Property.TextAlign;
    $spacing?: SpacingWithBreakpoints;
    $lineHeight?: CSS.Property.LineHeight;
}

import {
    Paragraph,
    Anchor,
    StyledSpan,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Heading6
} from './Typography.styled';

const TypographyComponent: React.FC<
    Props & { Component: React.ComponentType<StyledProps> }
> = props => {
    const { Component, size, weight, fontFamily, color, textAlign, spacing, lineHeight, ...rest } =
        props;

    return (
        <Component
            $size={size}
            $weight={weight}
            $fontFamily={fontFamily}
            $color={color}
            $textAlign={textAlign}
            $spacing={spacing}
            $lineHeight={lineHeight}
            {...rest}
        />
    );
};

export const P: React.FC<Props> = props => <TypographyComponent {...props} Component={Paragraph} />;

export const A: React.FC<Props> = props => <TypographyComponent {...props} Component={Anchor} />;

export const Span: React.FC<Props> = props => (
    <TypographyComponent {...props} Component={StyledSpan} />
);

export const H1: React.FC<Props> = props => <TypographyComponent {...props} Component={Heading1} />;

export const H2: React.FC<Props> = props => <TypographyComponent {...props} Component={Heading2} />;

export const H3: React.FC<Props> = props => <TypographyComponent {...props} Component={Heading3} />;

export const H4: React.FC<Props> = props => <TypographyComponent {...props} Component={Heading4} />;

export const H5: React.FC<Props> = props => <TypographyComponent {...props} Component={Heading5} />;

export const H6: React.FC<Props> = props => <TypographyComponent {...props} Component={Heading6} />;
