import styled, { css } from 'styled-components';
import type { StyledProps } from './Typography';

import {
    paragraph,
    anchor,
    heading1,
    heading2,
    heading3,
    heading4,
    heading5,
    heading6
} from 'Global/Typography';

const options = css<StyledProps>`
    ${({ theme, $weight, $size, $fontFamily, $color, $textAlign, $spacing, $lineHeight }) => css`
        ${$weight &&
        css`
            font-weight: ${$weight};
        `}

        ${$size && typeof $size === 'object'
            ? Object.keys($size).map(
                  key => css`
                      @media ${theme.breakpoints[key].media} {
                          font-size: ${$size[key]};
                      }
                  `
              )
            : css`
                  && {
                      font-size: ${$size};
                  }
              `}

        ${$fontFamily &&
        css`
            font-family: ${$fontFamily};
        `}

        ${$color &&
        css`
            color: ${$color};
        `}

        ${$textAlign &&
        css`
            text-align: ${$textAlign};
        `}

        ${$spacing &&
        Object.keys($spacing).map(
            breakpoint => css`
                @media ${theme.breakpoints[breakpoint].media} {
                    margin: ${$spacing[breakpoint].margin};
                    padding: ${$spacing[breakpoint].padding};
                }
            `
        )}
        
        ${$lineHeight &&
        css`
            line-height: ${$lineHeight};
        `}
    `}
`;

export const Paragraph = styled.p<StyledProps>`
    ${paragraph}
    ${options}
`;

export const Anchor = styled.a<StyledProps>`
    ${anchor}
    ${options}
`;

export const StyledSpan = styled.span<StyledProps>`
    display: block;
    ${options}
`;

export const Heading1 = styled.h1<StyledProps>`
    ${heading1}
    ${options}
`;

export const Heading2 = styled.h2<StyledProps>`
    ${heading2}
    ${options}
`;

export const Heading3 = styled.h3<StyledProps>`
    ${heading3}
    ${options}
`;

export const Heading4 = styled.h4<StyledProps>`
    ${heading4}
    ${options}
`;

export const Heading5 = styled.h5<StyledProps>`
    ${heading5}
    ${options}
`;

export const Heading6 = styled.h6<StyledProps>`
    ${heading6}
    ${options}
`;
